.MuiListItem-root.Mui-selected.MuiListItem-root.Mui-selected,
.MuiSelect-select:focus.MuiSelect-select:focus {
  background-color: #fff;
}
.floor-item.first.floor-item.first {
  background: linear-gradient(
      to bottom,
      #fff 1px,
      transparent 1px,
      transparent calc(100% - 1px),
      #828282 calc(100% - 1px)
    )
    no-repeat;
}
.floor-item:hover.floor-item:hover {
  color: #fff;
  background-color: rgba(10, 71, 237, 0.6);
}
.MuiInput-underline:before,
.MuiInput-underline:after,
.MuiSelect-icon {
  visibility: hidden;
}
.MuiPaper-rounded.MuiPaper-rounded {
  width: 168px;
  border-radius: 0.87vw;
  margin-top: 8px;
  margin-left: -15px;
}
@media screen and (min-width: 1440px) {
  .MuiPaper-rounded.MuiPaper-rounded {
    width: 11.66vw;
    border-radius: 0.87vw;
    margin-top: 4px;
    margin-left: -1.28vw;
  }
}
@media screen and (max-width: 1279px) and (min-width: 960px) {
  .MuiPaper-rounded.MuiPaper-rounded {
    width: 17.5vw;
    border-radius: 1.25vw;
    margin-top: 0.5vw;
    margin-left: -1.28vw;
  }
}
@media screen and (max-width: 959px) and (min-width: 768px) {
  .MuiPaper-rounded.MuiPaper-rounded {
    width: 39.19vw;
    border-radius: 1.56vw;
    margin-top: 0.5vw;
    margin-left: -1.56vw;
  }
}
@media screen and (max-width: 767px) {
  .MuiPaper-rounded.MuiPaper-rounded {
    width: 41.06vw;
    border-radius: 3.2vw;
    margin-top: 0.73vw;
    margin-left: -3.2vw;
  }
}
