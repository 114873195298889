.react-datepicker {
  box-shadow: 0px 4px 4px rgba(33, 35, 34, 0.2);
  box-shadow: 0px 4px 4px rgba(33, 35, 34, 0.2);
  border-radius: 0.87vw;
  padding: 0.87vw;
  margin-top: -8px;
}

.small.input-text.input-text {
  width: 13.12vw;
  height: 2.8vw;
  box-shadow: 0px 0px 0px rgba(33, 35, 34, 0.2);
  border: solid 1px #d6d6d6;
  font-family: WTGothic;
  cursor: pointer;
}

.small.input-icon {
  width: 1.5vw;
  height: auto;
  position: absolute;
  pointer-events: none;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header.react-datepicker__header {
  border: 0;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__navigation.react-datepicker__navigation {
  top: calc(1em + 0.87vw);
  line-height: 1.7em;
  height: 1.09vw;
  background-size: contain;
  border: 0;
  outline: none;
}

.react-datepicker__navigation--previous {
  background: url(../../assets/images/chevron-left.svg) no-repeat;
  border-right-color: #ccc;
  left: calc(1em + 0.87vw);
}

.react-datepicker__navigation--next {
  background: url(../../assets/images/chevron-right.svg) no-repeat;
  border-left-color: #ccc;
  right: calc(1em + 0.87vw);
}

.react-datepicker__day {
  height: 2.62vw;
  line-height: 2.62vw;
  width: 2.62vw;
  font-size: 0.87vw;
  font-family: WTGothic;
  font-weight: 400;
  border-radius: 50%;
  border-color: #ffffff;
  border-width: 0.14vw;
  border-style: solid;
  outline: none;
  margin: 0;
}

.react-datepicker__day--selected {
  border-radius: 50%;
  border-color: #0a47ed;
  color: #000;
  background-color: #ffffff;
  outline: none;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 50%;
  background-color: #ffffff;
  color: #000;
  outline: none;
}

.react-datepicker__day:hover {
  border-radius: 50%;
  background-color: #ff760c;
  color: #fff;
  outline: none;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 12px;
}

.react-datepicker__header {
  text-align: center;
  font-family: WTGothic;
  font-weight: 400;
  background-color: #ffffff;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.87vw;
  padding-top: 0.8em;
  position: relative;
}

.react-datepicker__current-month {
  font-family: Helvetica;
  margin-top: 0;
  color: rgba(0, 0, 0, 0.56);
  font-weight: 400;
  font-size: 1.31vw;
}

.react-datepicker__day-names {
  font-family: WTGothic;
  font-weight: 400;
  font-size: 0.95vw;
}

.react-datepicker__day-name {
  color: #000;
  display: inline-block;
  height: 1.09vw;
  line-height: 2.62vw;
  width: 2.62vw;
  text-align: center;
  margin: 0;
  border-radius: 50%;
  border-color: #ffffff;
  border-width: 0.14vw;
  border-style: solid;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.container-date {
  position: relative;
  min-width: 10vw;
}

@media screen and (min-width: 1440px) {
  .small.input-text.input-text {
    width: 12vw;
    height: 2.5vw;
    box-shadow: 0px 0px 0px rgba(33, 35, 34, 0.2);
    border-radius: 0.83vw;
    padding-left: 1.28vw;
    font-size: 0.97vw;
  }

  .small.input-icon {
    top: 7px;
    right: -1.5vw;
  }

  .react-datepicker {
    margin-left: 8vw;
  }
}

@media screen and (max-width: 1439px) and (min-width: 1280px) {
  .small.input-text.input-text {
    border-radius: 0.87vw;
    padding-left: 15px;
    font-size: 15px;
  }

  .small.input-icon {
    top: 8px;
    right: -1.9vw;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .react-datepicker {
    margin-left: 2vw;
  }
}

@media screen and (min-width: 960px) {
  .container-date {
    max-width: 4vw;
  }
}

@media screen and (max-width: 1279px) and (min-width: 960px) {
  .react-datepicker__day {
    height: 3.65vw;
    line-height: 3.65vw;
    width: 3.65vw;
    margin: 0.4vw;
    font-size: 1.25vw;
  }

  .react-datepicker__day-name {
    color: #000;
    display: inline-block;
    height: 1.09vw;
    line-height: 3.65vw;
    width: 3.65vw;
    margin: 0.4vw;
    font-size: 1.25vw;
  }

  .react-datepicker__current-month {
    font-size: 1.66vw;
  }

  .small.input-text.input-text {
    width: 17.5vw;
    height: 3.75vw;
    box-shadow: 0px 0px 0px rgba(33, 35, 34, 0.2);
    border-radius: 1.25vw;
    font-size: 1.45vw;
    padding-left: 1.28vw;
  }

  .small.input-icon {
    top: 6px;
    right: -5.9vw;
    width: 2.5vw;
  }
}

@media screen and (max-width: 959px) and (min-width: 768px) {
  .react-datepicker__day {
    height: 3.65vw;
    line-height: 3.65vw;
    width: 3.65vw;
    margin: 0.4vw;
    font-size: 1.6vw;
  }

  .react-datepicker__day-name {
    color: #000;
    display: inline-block;
    height: 1.09vw;
    line-height: 3.65vw;
    width: 3.65vw;
    margin: 0.4vw;
    font-size: 1.6vw;
  }

  .react-datepicker__current-month {
    font-size: 1.82vw;
  }

  .small.input-text.input-text {
    width: 39vw;
    height: 4.65vw;
    box-shadow: 0px 0px 0px rgba(33, 35, 34, 0.2);
    font-size: 1.82vw;
    padding-left: 1.56vw;
    border-radius: 1.56vw;
  }

  .small.input-icon {
    top: 6px;
    left: 33.9vw;
    width: 3vw;
  }

  .react-datepicker {
    margin-left: -4.5vw;
  }
}

@media screen and (max-width: 767px) {
  .react-datepicker__day {
    height: 7.9vw;
    line-height: 7.9vw;
    width: 7.9vw;
    margin: 1vw;
    font-size: 3.33vw;
  }

  .react-datepicker__day-name {
    color: #000;
    display: inline-block;
    height: 2vw;
    line-height: 7.9vw;
    width: 7.9vw;
    margin: 1vw;
    font-size: 3.33vw;
  }

  .react-datepicker__current-month {
    font-size: 3.9vw;
  }

  .react-datepicker__navigation.react-datepicker__navigation {
    top: calc(1em + 0.87vw);
    line-height: 1.7em;
    height: 5vw;
  }

  .small.input-text.input-text {
    width: 41vw;
    height: 9.5vw;
    box-shadow: 0px 0px 0px rgba(33, 35, 34, 0.2);
    border-radius: 3.2vw;
    padding-left: 3.2vw;
    font-size: 3.73vw;
  }

  .small.input-icon {
    top: 1.8vw;
    width: 5.67vw;
    height: 5.55vw;
    left: 32vw;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .react-datepicker {
    margin-left: 3vw;
  }
  .notAdmin.react-datepicker {
    margin-left: -25vw;
  }
}
