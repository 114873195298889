@font-face {
  font-family: 'WTGothic';
  src: local('WTGothic'), url(./fonts/WTGothic-Regular.woff2) format('woff');
}

@font-face {
  font-family: 'WTGothic';
  font-weight: 900;
  src: local('WTGothic'), url(./fonts/WTGothic-Bold.woff2) format('woff');
}

@font-face {
  font-family: 'WTGothic';
  font-weight: 700;
  src: local('WTGothic'), url(./fonts/WTGothic-Semi-Bold.woff2) format('woff');
}

@font-face {
  font-family: 'WTGothic';
  font-weight: 300;
  src: local('WTGothic'), url(./fonts/WTGothic-Light.woff2) format('woff');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #app, #app>div {
  height: 100vh;
  font-size: 16px;
}

:root {
  font-size: 16px;
  }
